/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SVVariantInfo
 */
export interface SVVariantInfo {
    /**
     * variant id
     * @type {string}
     * @memberof SVVariantInfo
     */
    variantId: string;
    /**
     * variant type
     * @type {string}
     * @memberof SVVariantInfo
     */
    variantType?: string;
    /**
     * variant id from sv vcf table
     * @type {string}
     * @memberof SVVariantInfo
     */
    variantIDVCF?: string;
    /**
     * Consequence(s) + associated gene(s)
     * @type {string}
     * @memberof SVVariantInfo
     */
    consequenceGenes?: string;
    /**
     * POS - END
     * @type {string}
     * @memberof SVVariantInfo
     */
    position?: string;
    /**
     * size
     * @type {number}
     * @memberof SVVariantInfo
     */
    size?: number;
    /**
     * African Ancestry Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    afrAlleleCount?: number;
    /**
     * African Ancestry Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    afrAlleleNumber?: number;
    /**
     * African Ancestry Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    afrAlleleFrequency?: number;
    /**
     * African Ancestry Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    afrHomozygoteCount?: number;
    /**
     * East Asian Ancestry Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    easAlleleCount?: number;
    /**
     * East Asian Ancestry Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    easAlleleNumber?: number;
    /**
     * East Asian Ancestry Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    easAlleleFrequency?: number;
    /**
     * East Asian Ancestry Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    easHomozygoteCount?: number;
    /**
     * European Ancestry Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    eurAlleleCount?: number;
    /**
     * European Ancestry Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    eurAlleleNumber?: number;
    /**
     * European Ancestry Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    eurAlleleFrequency?: number;
    /**
     * European Ancestry Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    eurHomozygoteCount?: number;
    /**
     * Latin American Ancestry Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    amrAlleleCount?: number;
    /**
     * Latin American Ancestry Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    amrAlleleNumber?: number;
    /**
     * Latin American Ancestry Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    amrAlleleFrequency?: number;
    /**
     * Latin American Ancestry Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    amrHomozygoteCount?: number;
    /**
     * Middle Eastern Ancestry Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    midAlleleCount?: number;
    /**
     * Middle Eastern Ancestry Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    midAlleleNumber?: number;
    /**
     * Middle Eastern Ancestry Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    midAlleleFrequency?: number;
    /**
     * Middle Eastern Ancestry Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    midHomozygoteCount?: number;
    /**
     * South Asian Ancestry Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    sasAlleleCount?: number;
    /**
     * South Asian Ancestry Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    sasAlleleNumber?: number;
    /**
     * South Asian Ancestry Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    sasAlleleFrequency?: number;
    /**
     * South Asian Ancestry Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    sasHomozygoteCount?: number;
    /**
     * Other Ancestry Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    othAlleleCount?: number;
    /**
     * Other Ancestry Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    othAlleleNumber?: number;
    /**
     * Other Ancestry Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    othAlleleFrequency?: number;
    /**
     * Other Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    othHomozygoteCount?: number;
    /**
     * Total Allele Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    totalAlleleCount?: number;
    /**
     * Total Allele Number
     * @type {number}
     * @memberof SVVariantInfo
     */
    totalAlleleNumber?: number;
    /**
     * Total Allele Frequency
     * @type {number}
     * @memberof SVVariantInfo
     */
    totalAlleleFrequency?: number;
    /**
     * Total Homozygote Count
     * @type {number}
     * @memberof SVVariantInfo
     */
    totalHomozygoteCount?: number;
}

/**
 * Check if a given object implements the SVVariantInfo interface.
 */
export function instanceOfSVVariantInfo(value: object): value is SVVariantInfo {
    if (!('variantId' in value) || value['variantId'] === undefined) return false;
    return true;
}

export function SVVariantInfoFromJSON(json: any): SVVariantInfo {
    return SVVariantInfoFromJSONTyped(json, false);
}

export function SVVariantInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SVVariantInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'variantId': json['variantId'],
        'variantType': json['variantType'] == null ? undefined : json['variantType'],
        'variantIDVCF': json['variantIDVCF'] == null ? undefined : json['variantIDVCF'],
        'consequenceGenes': json['consequenceGenes'] == null ? undefined : json['consequenceGenes'],
        'position': json['position'] == null ? undefined : json['position'],
        'size': json['size'] == null ? undefined : json['size'],
        'afrAlleleCount': json['afrAlleleCount'] == null ? undefined : json['afrAlleleCount'],
        'afrAlleleNumber': json['afrAlleleNumber'] == null ? undefined : json['afrAlleleNumber'],
        'afrAlleleFrequency': json['afrAlleleFrequency'] == null ? undefined : json['afrAlleleFrequency'],
        'afrHomozygoteCount': json['afrHomozygoteCount'] == null ? undefined : json['afrHomozygoteCount'],
        'easAlleleCount': json['easAlleleCount'] == null ? undefined : json['easAlleleCount'],
        'easAlleleNumber': json['easAlleleNumber'] == null ? undefined : json['easAlleleNumber'],
        'easAlleleFrequency': json['easAlleleFrequency'] == null ? undefined : json['easAlleleFrequency'],
        'easHomozygoteCount': json['easHomozygoteCount'] == null ? undefined : json['easHomozygoteCount'],
        'eurAlleleCount': json['eurAlleleCount'] == null ? undefined : json['eurAlleleCount'],
        'eurAlleleNumber': json['eurAlleleNumber'] == null ? undefined : json['eurAlleleNumber'],
        'eurAlleleFrequency': json['eurAlleleFrequency'] == null ? undefined : json['eurAlleleFrequency'],
        'eurHomozygoteCount': json['eurHomozygoteCount'] == null ? undefined : json['eurHomozygoteCount'],
        'amrAlleleCount': json['amrAlleleCount'] == null ? undefined : json['amrAlleleCount'],
        'amrAlleleNumber': json['amrAlleleNumber'] == null ? undefined : json['amrAlleleNumber'],
        'amrAlleleFrequency': json['amrAlleleFrequency'] == null ? undefined : json['amrAlleleFrequency'],
        'amrHomozygoteCount': json['amrHomozygoteCount'] == null ? undefined : json['amrHomozygoteCount'],
        'midAlleleCount': json['midAlleleCount'] == null ? undefined : json['midAlleleCount'],
        'midAlleleNumber': json['midAlleleNumber'] == null ? undefined : json['midAlleleNumber'],
        'midAlleleFrequency': json['midAlleleFrequency'] == null ? undefined : json['midAlleleFrequency'],
        'midHomozygoteCount': json['midHomozygoteCount'] == null ? undefined : json['midHomozygoteCount'],
        'sasAlleleCount': json['sasAlleleCount'] == null ? undefined : json['sasAlleleCount'],
        'sasAlleleNumber': json['sasAlleleNumber'] == null ? undefined : json['sasAlleleNumber'],
        'sasAlleleFrequency': json['sasAlleleFrequency'] == null ? undefined : json['sasAlleleFrequency'],
        'sasHomozygoteCount': json['sasHomozygoteCount'] == null ? undefined : json['sasHomozygoteCount'],
        'othAlleleCount': json['othAlleleCount'] == null ? undefined : json['othAlleleCount'],
        'othAlleleNumber': json['othAlleleNumber'] == null ? undefined : json['othAlleleNumber'],
        'othAlleleFrequency': json['othAlleleFrequency'] == null ? undefined : json['othAlleleFrequency'],
        'othHomozygoteCount': json['othHomozygoteCount'] == null ? undefined : json['othHomozygoteCount'],
        'totalAlleleCount': json['totalAlleleCount'] == null ? undefined : json['totalAlleleCount'],
        'totalAlleleNumber': json['totalAlleleNumber'] == null ? undefined : json['totalAlleleNumber'],
        'totalAlleleFrequency': json['totalAlleleFrequency'] == null ? undefined : json['totalAlleleFrequency'],
        'totalHomozygoteCount': json['totalHomozygoteCount'] == null ? undefined : json['totalHomozygoteCount'],
    };
}

export function SVVariantInfoToJSON(value?: SVVariantInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'variantId': value['variantId'],
        'variantType': value['variantType'],
        'variantIDVCF': value['variantIDVCF'],
        'consequenceGenes': value['consequenceGenes'],
        'position': value['position'],
        'size': value['size'],
        'afrAlleleCount': value['afrAlleleCount'],
        'afrAlleleNumber': value['afrAlleleNumber'],
        'afrAlleleFrequency': value['afrAlleleFrequency'],
        'afrHomozygoteCount': value['afrHomozygoteCount'],
        'easAlleleCount': value['easAlleleCount'],
        'easAlleleNumber': value['easAlleleNumber'],
        'easAlleleFrequency': value['easAlleleFrequency'],
        'easHomozygoteCount': value['easHomozygoteCount'],
        'eurAlleleCount': value['eurAlleleCount'],
        'eurAlleleNumber': value['eurAlleleNumber'],
        'eurAlleleFrequency': value['eurAlleleFrequency'],
        'eurHomozygoteCount': value['eurHomozygoteCount'],
        'amrAlleleCount': value['amrAlleleCount'],
        'amrAlleleNumber': value['amrAlleleNumber'],
        'amrAlleleFrequency': value['amrAlleleFrequency'],
        'amrHomozygoteCount': value['amrHomozygoteCount'],
        'midAlleleCount': value['midAlleleCount'],
        'midAlleleNumber': value['midAlleleNumber'],
        'midAlleleFrequency': value['midAlleleFrequency'],
        'midHomozygoteCount': value['midHomozygoteCount'],
        'sasAlleleCount': value['sasAlleleCount'],
        'sasAlleleNumber': value['sasAlleleNumber'],
        'sasAlleleFrequency': value['sasAlleleFrequency'],
        'sasHomozygoteCount': value['sasHomozygoteCount'],
        'othAlleleCount': value['othAlleleCount'],
        'othAlleleNumber': value['othAlleleNumber'],
        'othAlleleFrequency': value['othAlleleFrequency'],
        'othHomozygoteCount': value['othHomozygoteCount'],
        'totalAlleleCount': value['totalAlleleCount'],
        'totalAlleleNumber': value['totalAlleleNumber'],
        'totalAlleleFrequency': value['totalAlleleFrequency'],
        'totalHomozygoteCount': value['totalHomozygoteCount'],
    };
}

