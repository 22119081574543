/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * analysis id constant map
 */
export type AnalysisIdConstant = 'PARTICIPANT_COUNT_ANALYSIS_ID' | 'GENDER_ANALYSIS' | 'COUNT_ANALYSIS_ID' | 'SURVEY_GENDER_COUNT_ANALYSIS_ID' | 'SURVEY_AGE_COUNT_ANALYSIS_ID' | 'SURVEY_PARTICIPANT_COUNT_ANALYSIS_ID' | 'GENDER_ANALYSIS_ID' | 'PARTICIPANT_COUNT_BY_DATE_ANALYSIS_ID' | 'AGE_ANALYSIS_ID' | 'AGE_GENDER_COMBINED_ANALYSIS_ID' | 'SURVEY_VERSION_PARTICIPANT_COUNT_ANALYSIS_ID' | 'SURVEY_VERSION_QUESTION_COUNT_ANALYSIS_ID' | 'SURVEY_COUNT_ANALYSIS_ID' | 'SURVEY_GENDER_ANALYSIS_ID' | 'SURVEY_AGE_ANALYSIS_ID' | 'SURVEY_VERSION_ANALYSIS_ID' | 'SURVEY_LOCATION_ANALYSIS_ID' | 'SURVEY_QUESTION_GENDER_COUNT_ANALYSIS_ID' | 'SURVEY_QUESTION_AGE_COUNT_ANALYSIS_ID' | 'EHR_GENDER_COUNT_ANALYSIS_ID' | 'EHR_AGE_COUNT_ANALYSIS_ID' | 'RACE_ANALYSIS_ID' | 'ETHNICITY_ANALYSIS_ID' | 'MEASUREMENT_DIST_ANALYSIS_ID' | 'MEASUREMENT_GENDER_ANALYSIS_ID' | 'MEASUREMENT_GENDER_UNIT_ANALYSIS_ID' | 'RACE_ANALYSIS' | 'ETHNICITY_ANALYSIS' | 'GENO_GENDER_ANALYSIS' | 'GENO_AGE_ANALYSIS' | 'GENO_RACE_ANALYSIS' | 'GENO_LOCATION_ANALYSIS' | 'LOCATION_ANALYSIS_ID';

export const AnalysisIdConstant = {
    ParticipantCountAnalysisId: 'PARTICIPANT_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    GenderAnalysis: 'GENDER_ANALYSIS' as AnalysisIdConstant,
    CountAnalysisId: 'COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyGenderCountAnalysisId: 'SURVEY_GENDER_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyAgeCountAnalysisId: 'SURVEY_AGE_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyParticipantCountAnalysisId: 'SURVEY_PARTICIPANT_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    GenderAnalysisId: 'GENDER_ANALYSIS_ID' as AnalysisIdConstant,
    ParticipantCountByDateAnalysisId: 'PARTICIPANT_COUNT_BY_DATE_ANALYSIS_ID' as AnalysisIdConstant,
    AgeAnalysisId: 'AGE_ANALYSIS_ID' as AnalysisIdConstant,
    AgeGenderCombinedAnalysisId: 'AGE_GENDER_COMBINED_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyVersionParticipantCountAnalysisId: 'SURVEY_VERSION_PARTICIPANT_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyVersionQuestionCountAnalysisId: 'SURVEY_VERSION_QUESTION_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyCountAnalysisId: 'SURVEY_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyGenderAnalysisId: 'SURVEY_GENDER_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyAgeAnalysisId: 'SURVEY_AGE_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyVersionAnalysisId: 'SURVEY_VERSION_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyLocationAnalysisId: 'SURVEY_LOCATION_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyQuestionGenderCountAnalysisId: 'SURVEY_QUESTION_GENDER_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    SurveyQuestionAgeCountAnalysisId: 'SURVEY_QUESTION_AGE_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    EhrGenderCountAnalysisId: 'EHR_GENDER_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    EhrAgeCountAnalysisId: 'EHR_AGE_COUNT_ANALYSIS_ID' as AnalysisIdConstant,
    RaceAnalysisId: 'RACE_ANALYSIS_ID' as AnalysisIdConstant,
    EthnicityAnalysisId: 'ETHNICITY_ANALYSIS_ID' as AnalysisIdConstant,
    MeasurementDistAnalysisId: 'MEASUREMENT_DIST_ANALYSIS_ID' as AnalysisIdConstant,
    MeasurementGenderAnalysisId: 'MEASUREMENT_GENDER_ANALYSIS_ID' as AnalysisIdConstant,
    MeasurementGenderUnitAnalysisId: 'MEASUREMENT_GENDER_UNIT_ANALYSIS_ID' as AnalysisIdConstant,
    RaceAnalysis: 'RACE_ANALYSIS' as AnalysisIdConstant,
    EthnicityAnalysis: 'ETHNICITY_ANALYSIS' as AnalysisIdConstant,
    GenoGenderAnalysis: 'GENO_GENDER_ANALYSIS' as AnalysisIdConstant,
    GenoAgeAnalysis: 'GENO_AGE_ANALYSIS' as AnalysisIdConstant,
    GenoRaceAnalysis: 'GENO_RACE_ANALYSIS' as AnalysisIdConstant,
    GenoLocationAnalysis: 'GENO_LOCATION_ANALYSIS' as AnalysisIdConstant,
    LocationAnalysisId: 'LOCATION_ANALYSIS_ID' as AnalysisIdConstant
};

