/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Analysis } from './Analysis';
import {
    AnalysisFromJSON,
    AnalysisFromJSONTyped,
    AnalysisToJSON,
} from './Analysis';

/**
 * 
 * @export
 * @interface ConceptAnalysis
 */
export interface ConceptAnalysis {
    /**
     * concept id
     * @type {string}
     * @memberof ConceptAnalysis
     */
    conceptId: string;
    /**
     * 
     * @type {Analysis}
     * @memberof ConceptAnalysis
     */
    countAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof ConceptAnalysis
     */
    genderAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof ConceptAnalysis
     */
    ageAnalysis?: Analysis;
    /**
     * measurement value gender analysis
     * @type {Array<Analysis>}
     * @memberof ConceptAnalysis
     */
    measurementValueGenderAnalysis?: Array<Analysis>;
    /**
     * age analysis for measurement values
     * @type {Array<Analysis>}
     * @memberof ConceptAnalysis
     */
    measurementValueAgeAnalysis?: Array<Analysis>;
    /**
     * measurement distribution analysis
     * @type {Array<Analysis>}
     * @memberof ConceptAnalysis
     */
    measurementDistributionAnalysis?: Array<Analysis>;
    /**
     * measurement gender count by unit analysis
     * @type {Array<Analysis>}
     * @memberof ConceptAnalysis
     */
    measurementGenderCountAnalysis?: Array<Analysis>;
    /**
     * 
     * @type {Analysis}
     * @memberof ConceptAnalysis
     */
    ehrCountAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof ConceptAnalysis
     */
    participantCountAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof ConceptAnalysis
     */
    locationAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof ConceptAnalysis
     */
    combinedAgeGenderAnalysis?: Analysis;
}

/**
 * Check if a given object implements the ConceptAnalysis interface.
 */
export function instanceOfConceptAnalysis(value: object): value is ConceptAnalysis {
    if (!('conceptId' in value) || value['conceptId'] === undefined) return false;
    return true;
}

export function ConceptAnalysisFromJSON(json: any): ConceptAnalysis {
    return ConceptAnalysisFromJSONTyped(json, false);
}

export function ConceptAnalysisFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptAnalysis {
    if (json == null) {
        return json;
    }
    return {
        
        'conceptId': json['conceptId'],
        'countAnalysis': json['countAnalysis'] == null ? undefined : AnalysisFromJSON(json['countAnalysis']),
        'genderAnalysis': json['genderAnalysis'] == null ? undefined : AnalysisFromJSON(json['genderAnalysis']),
        'ageAnalysis': json['ageAnalysis'] == null ? undefined : AnalysisFromJSON(json['ageAnalysis']),
        'measurementValueGenderAnalysis': json['measurementValueGenderAnalysis'] == null ? undefined : ((json['measurementValueGenderAnalysis'] as Array<any>).map(AnalysisFromJSON)),
        'measurementValueAgeAnalysis': json['measurementValueAgeAnalysis'] == null ? undefined : ((json['measurementValueAgeAnalysis'] as Array<any>).map(AnalysisFromJSON)),
        'measurementDistributionAnalysis': json['measurementDistributionAnalysis'] == null ? undefined : ((json['measurementDistributionAnalysis'] as Array<any>).map(AnalysisFromJSON)),
        'measurementGenderCountAnalysis': json['measurementGenderCountAnalysis'] == null ? undefined : ((json['measurementGenderCountAnalysis'] as Array<any>).map(AnalysisFromJSON)),
        'ehrCountAnalysis': json['ehrCountAnalysis'] == null ? undefined : AnalysisFromJSON(json['ehrCountAnalysis']),
        'participantCountAnalysis': json['participantCountAnalysis'] == null ? undefined : AnalysisFromJSON(json['participantCountAnalysis']),
        'locationAnalysis': json['locationAnalysis'] == null ? undefined : AnalysisFromJSON(json['locationAnalysis']),
        'combinedAgeGenderAnalysis': json['combinedAgeGenderAnalysis'] == null ? undefined : AnalysisFromJSON(json['combinedAgeGenderAnalysis']),
    };
}

export function ConceptAnalysisToJSON(value?: ConceptAnalysis | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conceptId': value['conceptId'],
        'countAnalysis': AnalysisToJSON(value['countAnalysis']),
        'genderAnalysis': AnalysisToJSON(value['genderAnalysis']),
        'ageAnalysis': AnalysisToJSON(value['ageAnalysis']),
        'measurementValueGenderAnalysis': value['measurementValueGenderAnalysis'] == null ? undefined : ((value['measurementValueGenderAnalysis'] as Array<any>).map(AnalysisToJSON)),
        'measurementValueAgeAnalysis': value['measurementValueAgeAnalysis'] == null ? undefined : ((value['measurementValueAgeAnalysis'] as Array<any>).map(AnalysisToJSON)),
        'measurementDistributionAnalysis': value['measurementDistributionAnalysis'] == null ? undefined : ((value['measurementDistributionAnalysis'] as Array<any>).map(AnalysisToJSON)),
        'measurementGenderCountAnalysis': value['measurementGenderCountAnalysis'] == null ? undefined : ((value['measurementGenderCountAnalysis'] as Array<any>).map(AnalysisToJSON)),
        'ehrCountAnalysis': AnalysisToJSON(value['ehrCountAnalysis']),
        'participantCountAnalysis': AnalysisToJSON(value['participantCountAnalysis']),
        'locationAnalysis': AnalysisToJSON(value['locationAnalysis']),
        'combinedAgeGenderAnalysis': AnalysisToJSON(value['combinedAgeGenderAnalysis']),
    };
}

